import { useEffect, useState } from 'react';

import GlobalFetch from 'alova/GlobalFetch';
import { createAlova } from 'alova';

// 获取新令牌的函数
const refreshToken = async (refreshToken) => {
  const response = await fetch('https://services.cloud.mongodb.com/api/client/v2.0/auth/session', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${refreshToken}`
    }
  });

  if (!response.ok) {
    throw new Error('Failed to refresh token');
  }

  const data = await response.json();
  return data.access_token;
};

const useAuth = (initialRefreshToken) => {
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadInitialToken = async () => {
      try {
        const initialAccessToken = await refreshToken(initialRefreshToken);
        setAccessToken(initialAccessToken);
        setLoading(false);
      } catch (error) {
        console.error('Error loading initial token', error);
      }
    };

    loadInitialToken();
  }, [initialRefreshToken]);

  useEffect(() => {
    if (!accessToken) return;

    // 每25分钟刷新令牌
    const interval = setInterval(async () => {
      try {
        const newAccessToken = await refreshToken(initialRefreshToken);
        setAccessToken(newAccessToken);
      } catch (error) {
        console.error('Error refreshing token', error);
      }
    }, 25 * 60 * 1000);

    return () => clearInterval(interval);
  }, [accessToken, initialRefreshToken]);

  // console.log('accessToken', accessToken);

  return { accessToken, loading };
};

export default useAuth;