import { AppBar, Container, CssBaseline, Toolbar, Typography } from '@mui/material';

import KanbanBoard from './components/KanbanBoard';
import React from 'react';

const App = () => {
  return (
    <div>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            看板程序
          </Typography>
        </Toolbar>
      </AppBar>
      <Container sx={{ padding: '0' }}>
        <KanbanBoard />
      </Container>
    </div>
  );
};

export default App;
