import { Box } from '@mui/material';
import EmployeeCard from './EmployeeCard';
import React from 'react';
import TaskCard from './TaskCard';

const CardGroup = ({ task, employeeGroup, employee, onClick }) => (
  <Box onClick={onClick} sx={{ cursor: 'pointer' }}>
    <TaskCard task={task} />
    {employeeGroup.map((id, index) => (
      <EmployeeCard key={index} id={id} name={employee[id].name} license={employee[id].license} position={employee[id].position} onClick={onClick} />
    ))}
  </Box>
);

export default CardGroup;