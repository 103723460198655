import {
  Card,
  CardContent,
  Typography
} from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2'
import React from 'react';

const getBackgroundColor = (license) => {
  switch (license) {
    case '电力':
      return 'lightblue';
    case '内燃':
      return 'lightyellow';
    case '双证':
      return 'lightgreen';
    default:
      return 'white';
  }
};

const EmployeeCard = ({ id, name, license, position, onClick }) => (
  <Grid item sx={{ padding: '1px 2px !important' }}>
    <Card
      sx={{
        display: 'flex',
        height: '80px',
        writingMode: 'vertical-rl',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        width: '45px',
        cursor: 'pointer',
        backgroundColor: getBackgroundColor(license),
      }}
      onClick={onClick}
      variant="outlined"
    >
      <CardContent sx={{ padding: '0px !important', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Typography variant="body2" align="center" sx={{ fontWeight: position === '司机' ? '700' : '300' }}>
          {name}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

export default EmployeeCard;