import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import CardGroup from './CardGroup';
import GlobalFetch from 'alova/GlobalFetch';
import Grid from '@mui/material/Unstable_Grid2';
import ListSubheader from '@mui/material/ListSubheader';
import Pinyin from 'js-pinyin';
import { createAlova } from 'alova';
import useAuth from './useAuth';

Pinyin.setOptions({ checkPolyphone: false, charCase: 0 });

const alovaInstance = createAlova({
  baseURL: 'https://us-west-2.aws.data.mongodb-api.com/app/data-fwqeukz/endpoint/data/v1/action',
  requestAdapter: GlobalFetch(),
});

// 获取任务数据
const getTasks = async (accessToken) => {
  const response = await alovaInstance.Post(
    '/aggregate',
    {
      dataSource: 'ServerlessInstance0',
      database: 'kanban',
      collection: 'task',
      pipeline: [
        {
          "$unwind": "$task_list"
        },
        {
          "$lookup": {
            "from": "employee_group",
            "localField": "task_list.employee_group",
            "foreignField": "_id",
            "as": "task_list.group"
          }
        },
        {
          "$unwind": "$task_list.group"
        },
        {
          "$unwind": "$task_list.group.group"
        },
        {
          "$lookup": {
            "from": "employee",
            "localField": "task_list.group.group",
            "foreignField": "id",
            "as": "task_list.group.employee_info"
          }
        },
        {
          "$unwind": "$task_list.group.employee_info"
        },
        {
          "$group": {
            "_id": {
              "task_id": "$_id",
              "task_list_name": "$task_list.name",
              "employee_group_id": "$task_list.group._id",
              "task_list_order": "$task_list.order"
            },
            "name": { "$first": "$name" },
            "task_order": { "$first": "$order" },
            "task_list_name": { "$first": "$task_list.name" },
            "group_id": { "$first": "$task_list.group._id" },
            "employees": {
              "$push": {
                "_id": "$task_list.group.employee_info._id",
                "id": "$task_list.group.employee_info.id",
                "name": "$task_list.group.employee_info.name",
                "license": "$task_list.group.employee_info.license",
                "position": "$task_list.group.employee_info.position"
              }
            }
          }
        },
        {
          "$group": {
            "_id": "$_id.task_id",
            "name": { "$first": "$name" },
            "task_order": { "$first": "$task_order" },
            "task_list": {
              "$push": {
                "name": "$task_list_name",
                "order": "$_id.task_list_order",
                "group": {
                  "_id": "$group_id",
                  "group": "$employees"
                }
              }
            }
          }
        },
        {
          "$project": {
            "name": 1,
            "task_order": 1, // Include the top-level order field in the projection
            "task_list": {
              "$filter": {
                "input": "$task_list",
                "as": "item",
                "cond": { "$ne": ["$$item.order", null] }
              }
            }
          }
        },
        {
          "$addFields": {
            "task_list": {
              "$sortArray": {
                "input": "$task_list",
                "sortBy": { "order": 1 }
              }
            }
          }
        },
        {
          "$sort": { "task_order": 1 } // Sort the top-level documents by their order field
        }
      ]
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
  );

  const data = (await response.json()).documents;

  return data;
};

// 获取员工数据
const getEmployees = async (accessToken) => {
  const response = await alovaInstance.Post(
    '/find',
    {
      dataSource: 'ServerlessInstance0',
      database: 'kanban',
      collection: 'employee',
      filter: {}
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    }
  );

  const data = (await response.json()).documents;

  return data;
};

// 获取员工组数据
const getEmployeeGroups = async (accessToken) => {
  const response = await alovaInstance.Post(
    '/find',
    {
      dataSource: 'ServerlessInstance0',
      database: 'kanban',
      collection: 'employee_group',
      filter: {}
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
  );

  const data = (await response.json()).documents;

  return data;
};

// 获取员工的菜单项
const generateEmployeeMenuItems = (employees) => {
  const filteredEmployees = employees.filter(employee => employee.id !== '0' && typeof employee.name === 'string');

  // 按名字的拼音首字母排序
  filteredEmployees.sort((a, b) => {
    const aPinyin = Pinyin.getCamelChars(a.name);
    const bPinyin = Pinyin.getCamelChars(b.name);
    return aPinyin.localeCompare(bPinyin);
  });

  // 创建分组后的组件列表
  const groupedItems = [
    <ListSubheader key='操作'>操作</ListSubheader>,
    <MenuItem key='0' value='0'>
      移动到备用
    </MenuItem>
  ];
  let currentLetter = '';

  filteredEmployees.forEach(employee => {
    const firstLetter = Pinyin.getCamelChars(employee.name.charAt(0)).toUpperCase();
    if (firstLetter !== currentLetter) {
      currentLetter = firstLetter;
      groupedItems.push(
        <ListSubheader key={currentLetter}>{currentLetter}</ListSubheader>
      );
    }
    groupedItems.push(
      <MenuItem key={employee.id} value={employee.id}>
        {employee.id} - {employee.name}
      </MenuItem>
    );
  });

  return groupedItems;
};

const KanbanBoard = () => {
  const { accessToken, loading } = useAuth('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiYWFzX2RhdGEiOm51bGwsImJhYXNfZGV2aWNlX2lkIjoiMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwIiwiYmFhc19kb21haW5faWQiOiI2NjliY2JlYmE0NzMzM2ZkYTFlZWEwZDEiLCJiYWFzX2lkIjoiNjY5Y2M1NTVhZDFjMTFmNzkzZGViYjU1IiwiYmFhc19pZGVudGl0eSI6eyJpZCI6IjY2OWJjYzBkYTQ3MzMzZmRhMWVlYzA1YyIsInByb3ZpZGVyX3R5cGUiOiJhcGkta2V5IiwicHJvdmlkZXJfaWQiOiI2NjliY2JmMDhkN2JjOTI0ZDc2MjJmNDgifSwiZXhwIjoxODc5MjMwMTY1LCJpYXQiOjE3MjE1NTAxNjUsInN0aXRjaF9kYXRhIjpudWxsLCJzdGl0Y2hfZGV2SWQiOiIwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAiLCJzdGl0Y2hfZG9tYWluSWQiOiI2NjliY2JlYmE0NzMzM2ZkYTFlZWEwZDEiLCJzdGl0Y2hfaWQiOiI2NjljYzU1NWFkMWMxMWY3OTNkZWJiNTUiLCJzdGl0Y2hfaWRlbnQiOnsiaWQiOiI2NjliY2MwZGE0NzMzM2ZkYTFlZWMwNWMiLCJwcm92aWRlcl90eXBlIjoiYXBpLWtleSIsInByb3ZpZGVyX2lkIjoiNjY5YmNiZjA4ZDdiYzkyNGQ3NjIyZjQ4In0sInN1YiI6IjY2OWJjYzBkYTQ3MzMzZmRhMWVlYzA1NiIsInR5cCI6InJlZnJlc2gifQ.5_2zPyE7H3gMfCMsG-uZjIHP-N-3T7MPdvrf9P1dQeM');
  const [employeeData, setEmployeeData] = useState([]);
  const [taskData, setTaskData] = useState([]);

  // 控制员工组对话框的打开状态
  const [employeeGroupDialogOpen, setEmployeeGroupDialogOpen] = useState(false);
  // 选中的员工组
  const [selectedEmployeeGroup, setSelectedEmployeeGroup] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  // 当前分类的索引
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  // 当前组的索引，用于标识当前操作的组
  const [currentGroupIndex, setCurrentGroupIndex] = useState(null);
  // 上一个卡片的信息，用于回退操作
  const [previousCard, setPreviousCard] = useState(null);
  // 选中的名称，用于记录当前选中的员工或组名
  const [selectedName, setSelectedName] = useState('');
  // 移动的类别，用于标识员工移动的目标组
  const [moveClass, setMoveClass] = useState(currentGroupIndex);

  useEffect(() => {
    const loadData = async () => {
      if (!accessToken) return; // 确保 accessToken 存在
      try {
        console.log(accessToken);
        const tasks = await getTasks(accessToken);
        setTaskData(tasks);

        const employees = await getEmployees(accessToken);
        setEmployeeData(employees);
      } catch (error) {
        console.error('Error loading data', error);
      }
    };

    loadData();
  }, [accessToken]);


  /**
   * 处理员工组点击事件
   * 
   * 当用户点击员工组时，此函数被调用。它旨在更新当前选定的员工组、设置当前类别索引、
   * 并打开员工组对话框，以便用户可以查看或编辑选定的员工组。
   * 
   * @param {Object} task - 包含员工组信息的任务对象
   * @param {number} categoryIndex - 当前类别的索引，用于跟踪和管理不同的类别
   * @returns {void} 无返回值
   */
  const handleEmployeeGroupClick = async (task, categoryIndex) => {
    setSelectedEmployeeGroup([...task.group.group]);
    setSelectedCategoryIndex(categoryIndex);
    setSelectedTask(task);
    setEmployeeGroupDialogOpen(true);
  };

  const handleNameChange = async (event, positionIndex) => {
    const newEmployeeId = event.target.value;
    const oldEmployeeId = selectedEmployeeGroup[positionIndex].id;
    const groupId = selectedTask.group._id;
  
    try {
      await swapEmployeeInGroup(newEmployeeId, oldEmployeeId, positionIndex, groupId);
      const updatedTaskData = await getTasks(accessToken);
      setTaskData(updatedTaskData);
      setEmployeeGroupDialogOpen(false);  // 关闭对话框
    } catch (error) {
      console.error('Error swapping employees', error);
    }
  };

  const swapEmployeeInGroup = async (newEmployeeId, oldEmployeeId, positionIndex, groupId) => {
    if (newEmployeeId === "0") {
      // 更新组中的旧员工为0
      const payload = {
        dataSource: 'ServerlessInstance0',
        database: 'kanban',
        collection: 'employee_group',
        filter: {
          _id: { $oid: groupId },
          [`group.${positionIndex}`]: oldEmployeeId
        },
        update: {
          "$set": {
            [`group.${positionIndex}`]: newEmployeeId
          }
        }
      };
  
      console.log("Payload for updating old employee to 0:", JSON.stringify(payload, null, 2));
  
      const response = await alovaInstance.Post(
        '/updateOne',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );
  
      if (!response.ok) {
        throw new Error('Failed to update employee group');
      }
  
      const data = await response.json();
      return data;
  
    } else {
      // 找到旧员工所在的组
      const oldEmployeeGroupPayload = {
        dataSource: 'ServerlessInstance0',
        database: 'kanban',
        collection: 'employee_group',
        filter: {
          _id: { $oid: groupId }
        }
      };
  
      console.log("Payload for finding old employee group:", JSON.stringify(oldEmployeeGroupPayload, null, 2));
  
      const oldEmployeeGroupResponse = await alovaInstance.Post(
        '/find',
        oldEmployeeGroupPayload,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );
  
      const oldEmployeeGroupData = (await oldEmployeeGroupResponse.json()).documents;
  
      if (oldEmployeeGroupData.length > 0) {
        const oldEmployeePositionIndex = oldEmployeeGroupData[0].group.indexOf(oldEmployeeId);
  
        // 找到新员工所在的组
        const newEmployeeGroupPayload = {
          dataSource: 'ServerlessInstance0',
          database: 'kanban',
          collection: 'employee_group',
          filter: {
            "group": newEmployeeId
          }
        };
  
        console.log("Payload for finding new employee group:", JSON.stringify(newEmployeeGroupPayload, null, 2));
  
        const newEmployeeGroupResponse = await alovaInstance.Post(
          '/find',
          newEmployeeGroupPayload,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );
  
        const newEmployeeGroupData = (await newEmployeeGroupResponse.json()).documents;
        console.log("New employee group data:", newEmployeeGroupData);
  
        if (newEmployeeGroupData.length > 0) {
          const newEmployeePositionIndex = newEmployeeGroupData[0].group.indexOf(newEmployeeId);
  
          // 更新旧员工位置为新员工
          const updateOldEmployeePayload = {
            dataSource: 'ServerlessInstance0',
            database: 'kanban',
            collection: 'employee_group',
            filter: {
              _id: { $oid: groupId },
              [`group.${oldEmployeePositionIndex}`]: oldEmployeeId
            },
            update: {
              "$set": {
                [`group.${oldEmployeePositionIndex}`]: newEmployeeId
              }
            }
          };
  
          console.log("Payload for updating old employee to new employee:", JSON.stringify(updateOldEmployeePayload, null, 2));
  
          const updateOldEmployeeResponse = await alovaInstance.Post(
            '/updateOne',
            updateOldEmployeePayload,
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              }
            }
          );
  
          if (!updateOldEmployeeResponse.ok) {
            throw new Error('Failed to update old employee to new employee');
          }
  
          // 更新新员工位置为旧员工
          const updateNewEmployeePayload = {
            dataSource: 'ServerlessInstance0',
            database: 'kanban',
            collection: 'employee_group',
            filter: {
              _id: { $oid: newEmployeeGroupData[0]._id },
              [`group.${newEmployeePositionIndex}`]: newEmployeeId
            },
            update: {
              "$set": {
                [`group.${newEmployeePositionIndex}`]: oldEmployeeId
              }
            }
          };
  
          console.log("Payload for updating new employee to old employee:", JSON.stringify(updateNewEmployeePayload, null, 2));
  
          const updateNewEmployeeResponse = await alovaInstance.Post(
            '/updateOne',
            updateNewEmployeePayload,
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              }
            }
          );
  
          if (!updateNewEmployeeResponse.ok) {
            throw new Error('Failed to update new employee to old employee');
          }
  
          const data = await updateNewEmployeeResponse.json();
          return data;
  
        } else {
          const response = await alovaInstance.Post(
            '/updateOne',
            {
              dataSource: 'ServerlessInstance0',
              database: 'kanban',
              collection: 'employee_group',
              filter: {
                _id: { $oid: groupId },
                [`group.${positionIndex}`]: oldEmployeeId
              },
              update: {
                "$set": {
                  [`group.${positionIndex}`]: newEmployeeId
                }
              }
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              }
            }
          )
        }
      } else {
        throw new Error('Old employee not found in any group');
      }
    }
  };

  const handleDialogClose = () => setEmployeeGroupDialogOpen(false);

  if (loading || !accessToken || taskData.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {taskData.map((taskCategory, categoryIndex) => (
        <Box key={categoryIndex} sx={{ marginTop: 4, marginBottom: 2, marginLeft: 0 }}>
          <Grid container spacing={1}>
            <Grid xs={2} sm={1}>
              <Box
                sx={{
                  display: 'flex',
                  writingMode: 'vertical-rl',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '65px',
                  cursor: 'pointer',
                }}
              >
                <Button>
                  <Typography variant="h6">
                    {taskCategory.name}
                  </Typography>
                </Button>
              </Box>
              
            </Grid>
            <Grid xs={10} sm={11}>
              <Grid container direction="row" rowSpacing={2} columnSpacing={1}>
                {taskCategory.task_list.map((task) => (
                  <CardGroup
                    key={task.group._id}
                    task={task.name}
                    employeeGroup={task.group.group.map(employee => employee.id)}
                    employee={task.group.group.reduce((acc, emp) => {
                      acc[emp.id] = emp;
                      return acc;
                    }, {})}
                    onClick={() => handleEmployeeGroupClick(task, categoryIndex)}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>       
        </Box>
      ))}

      <Dialog
        name="修改机班"
        open={employeeGroupDialogOpen}
        onClose={handleDialogClose}
        PaperProps={{ sx: { width: '80%', height: '80%' } }}
      >
        <DialogTitle>修改机班</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mb: 2, mt: 2, mr: 2, width: '47%' }}>
            <InputLabel id="move-class-label">班次</InputLabel>
            <Select
              labelId="move-class-label"
              value={moveClass}
              label='班次'
              onChange={(e) => setMoveClass(e.target.value)}
            >
              {taskData[selectedCategoryIndex].task_list.map((task, index) => (
                <MenuItem key={task.group._id} value={index}>
                  {task.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {[0, 1, 2].map((pos) => (
              <FormControl fullWidth sx={{ mb: 2, mt: 2, mr: 2, width: '47%' }} key={pos}>
                <InputLabel id={`select-label-${pos}`}>{['司机', '副司机', '学员'][pos]}</InputLabel>
                <Select
                  labelId={`select-label-${pos}`}
                  label={['司机', '副司机', '学员'][pos]}
                  value={selectedEmployeeGroup[pos]?.id || ''} // 修改value属性
                  onChange={(e) => handleNameChange(e, pos)}
                >
                  {generateEmployeeMenuItems(employeeData)}
                </Select>
              </FormControl>
            )
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default KanbanBoard;
