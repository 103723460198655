import {
  Card,
  CardContent,
  Typography
} from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2'
import React from 'react';

const TaskCard = ({ task }) => (
  <Grid item sx={{ padding: '1px 2px !important' }}>
    <Card
      sx={{
        display: 'flex',
        height: '80px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        width: '45px',
        cursor: 'pointer',
        backgroundColor: '#FAE58B',
      }}
      variant="outlined"
    >
      <CardContent sx={{ padding: '0px !important', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Typography variant="body2" align="center">
          {task}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

export default TaskCard;